<script>
export default {
  name: 'NotFound',
}
</script>

<template>
  <div :class="$classes['not-found']">
    <div :class="$classes['center']">
      <div :class="$classes['wrapper']">
        <div :class="$classes['content']">
          <h1 :class="$classes['title']">
            Erro 404
          </h1>
          <h2 :class="$classes['subtitle']">
            Você não conseguiu chegar ao seu destino.
          </h2>
          <p>Verifique o endereço ou vá para nossa <strong>página inicial</strong>.</p>
        </div>
        <div :class="$classes['logo-wrapper']">
          <img
            src="~@/assets/images/logo.png"
            :class="$classes['logo']"
          >
        </div>
      </div>
    </div>
  </div>
</template>

<style module>
.not-found {
  background: url('~@/assets/images/404.jpg') no-repeat;
  background-size: cover;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.center {
  width: 825px;
}

.wrapper {
  display: flex;
  align-items: center;
}

.content {
  flex: 1;
  color: #fff;
}

.content p {
  max-width: 430px;
  font-size: 22px;
  letter-spacing: 0.1em;
}

.title {
  font-size: 30px;
  font-weight: normal;
  line-height: 45px;
  letter-spacing: 0.1em;
  margin-bottom: 10px;
}

.subtitle {
  max-width: 580px;
  font-size: 40px;
  font-weight: bold;
  line-height: 60px;
  letter-spacing: 0.1em;
  margin-bottom: 20px;
}

.logo-wrapper {
  padding: 10px;
  background: #fff;
  border-radius: 5px;
}

.logo {
  display: block;
  width: 155px;
  height: auto;
}
</style>
